import React, { ReactNode, useId } from "react";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { cx } from "@libs/utils/cx";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { Switch, SwitchProps } from "@libs/components/UI/Switch";

export type FormatReadOnlyValue = (value: string | undefined | null, id: string) => ReactNode;

interface BaseProps {
  falseText?: string;
  trueText?: string;
}

export type FormFieldSwitchProps = BaseProps &
  Omit<FormFieldProps, "children" | "containerClassName" | "layout" | "placeholder"> &
  SwitchProps;

export const FormFieldSwitch: React.FC<FormFieldSwitchProps> = ({
  disabled,
  required,
  label,
  description,
  error,
  className,
  checked,
  onChange,
  id,
  edit = true,
  displayErrorMessage = true,
  falseText = "No",
  trueText = "Yes",
  labelPosition,
}) => {
  const fieldId = useEnsureId({ customId: id });
  const errorId = useId();

  const formFieldLabel = label && (
    <FormFieldLabel
      content={label}
      error={error}
      disabled={disabled}
      required={required}
      edit={edit}
      id={fieldId}
      className={cx("font-sansSemiBold text-xs", description ? "pb-1" : "pb-2")}
    />
  );

  const formFieldDescription = description && (
    <div className={cx("text-xs pb-2", disabled ? "text-slate-500" : "text-slate-700")}>{description}</div>
  );

  return (
    <div className={className}>
      {formFieldLabel}
      {formFieldDescription}
      <div className="flex items-center gap-x-3 py-2">
        {edit && (
          <Switch
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            id={fieldId}
            labelPosition={labelPosition}
          />
        )}
        <span {...(edit ? undefined : { ["aria-labelledby"]: fieldId })} className="text-xs">
          {checked ? trueText : falseText}
        </span>
      </div>
      {error && edit && displayErrorMessage && (
        <FormFieldError id={errorId} className="pt-1">
          {error}
        </FormFieldError>
      )}
    </div>
  );
};
