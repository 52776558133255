import { EmployeeStatus } from "@libs/api/employee/types";
import { getNextPageParam, makeInfiniteQuery, makeQuery, OrderBy } from "@libs/utils/queries";
import { oneMinuteCache } from "@libs/utils/queryCache";

export interface ApiEmployeeQueryParams {
  orderBy?: OrderBy;
  pageNumber: number;
  pageSize: number;
  searchString?: string;
  sortColumn?: string;
  statuses?: EmployeeStatus[];
  isOwner?: boolean;
  isAdmin?: boolean;
}

export const getEmployee = makeQuery({
  queryKey: ["practices", "getEmployee"],
  formatParams: ({
    practiceId,
    employeeId,
    ...query
  }: {
    practiceId: number;
    employeeId: number;
    includeRole?: boolean;
  }) => [practiceId, employeeId, query],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getInfiniteEmployees = makeInfiniteQuery({
  queryKey: ["practices", "getEmployees"],
  formatParams: ({ practiceId, ...query }: { practiceId: number } & ApiEmployeeQueryParams) => [
    practiceId,
    query,
  ],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});
