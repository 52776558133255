import { PracticeMigrationSettingsRequest, PracticeMigrationSettingsVO } from "@libs/api/generated-api";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { useObjectState } from "@libs/hooks/useObjectState";
import { FormEvent, useCallback, useMemo } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { upsertPracticeMigrationSettings } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { AdminPanel } from "components/UI/AdminPanel";
import { useSegmentLogger } from "contexts/AdminSegmentContext";
import { MigrationSettingsFormFields } from "components/Practice/MigrationSettings/MigrationSettingsFormFields";

const getDefaultDraft = (
  settings: Omit<PracticeMigrationSettingsVO, "practiceId" | "practiceUuid">
): PracticeMigrationSettingsRequest => {
  return {
    migrateBalances: settings.migrateBalances,
    migrateCustomCodes: settings.migrateCustomCodes,
    migrateInsurances: settings.migrateInsurances,
    migrateFeeSchedules: settings.migrateFeeSchedules,
    migrationInactivePatientDataInYears: settings.migrationInactivePatientDataInYears,
    migrationPms: settings.migrationPms,
    migrationImagingSolution: settings.migrationImagingSolution,
    migrationNotes: settings.migrationNotes,
  };
};

export const MigrationSettingsForm: React.FC<{
  settings: Omit<PracticeMigrationSettingsVO, "practiceId" | "practiceUuid">;
  edit: boolean;
  onCloseEditor: Func;
  practiceId: number;
}> = ({ settings, onCloseEditor, edit, practiceId }) => {
  const [draft, updateDraft] = useObjectState<PracticeMigrationSettingsRequest>(() =>
    getDefaultDraft(settings)
  );
  const [upsertMigrationSettingsMutation] = useApiMutations([upsertPracticeMigrationSettings]);
  const upsertSettingsMutate = upsertMigrationSettingsMutation.mutate;
  const settingsValue = useMemo((): PracticeMigrationSettingsRequest => {
    return edit ? draft : settings;
  }, [draft, edit, settings]);
  const logger = useSegmentLogger();
  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      upsertSettingsMutate(
        { practiceId, data: draft },
        {
          onSuccess: () => {
            logger.track(
              {
                event: "Updated Migration Settings",
                practiceId,
                domains: ["Migration Settings"],
              },
              {
                properties: draft,
              }
            );
            onCloseEditor();
          },
          onError: handleError,
        }
      );
    },
    [draft, logger, onCloseEditor, practiceId, upsertSettingsMutate]
  );

  return (
    <Form className="flex flex-col h-full min-h-0" onSubmit={handleSubmit}>
      <AdminPanel>
        <MigrationSettingsFormFields
          settings={settingsValue}
          handleUpdateSettings={updateDraft}
          edit={edit}
          className="pb-8"
        />
      </AdminPanel>
      {edit && (
        <div
          className={`
            flex
            gap-6
            justify-center
            flex-none
            p-4
            border-t
            border-t-slate-200
          `}
        >
          <Button onClick={onCloseEditor} theme="secondary" className="min-w-button">
            Cancel
          </Button>
          <AsyncButton
            isLoading={upsertMigrationSettingsMutation.isLoading}
            type="submit"
            theme="primary"
            className="min-w-button"
          >
            Save
          </AsyncButton>
        </div>
      )}
    </Form>
  );
};
