import { ReactNode } from "react";
import { AsyncSection } from "@libs/components/UI/AsyncSection";

export type Props = {
  queries?: { isLoading: boolean; isError: boolean }[];

  // queries that delay content display to avoid content shifting
  // but can error out and still display the desired content
  nonCriticalQueries?: { isLoading: boolean; isError: boolean }[];

  // Custom content to display before the first data loaded
  loading?: ReactNode;

  // Custom content to display when any data fails to load at all
  loadError?: ReactNode;
  children?: ReactNode;
  isDark?: boolean;
};

export const QueryResult: React.FC<Props> = ({ queries, nonCriticalQueries, children, ...props }) => {
  return (
    <AsyncSection
      isLoading={Boolean(
        queries?.some((query) => query.isLoading) || nonCriticalQueries?.some((query) => query.isLoading)
      )}
      // If the queries fail on initial load or refetch we want to show error info
      // in order to avoid showing outdated info
      // We could make this an option to say only consider this an error if isLoadingError
      // In the use case where leaving stale data is better not showing it
      isLoadingError={Boolean(queries?.some((query) => query.isError))}
      {...props}
    >
      {children}
    </AsyncSection>
  );
};
