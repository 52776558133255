import { AuthError, signInWithRedirect, SignInWithRedirectInput, signOut } from "@aws-amplify/auth";
import { ErrorResponse, HttpResponse } from "@libs/api/generated-api";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { isError } from "@libs/utils/types";
import { CognitoErrorCode } from "@libs/utils/cognito";

export const isMFARequiredError = (response: HttpResponse<unknown, ErrorResponse | undefined>) =>
  isHttpResponseError(response) && response.error.errors?.[0]?.errorCode === "MFA_REQUIRED";

export const useSignInWithRedirect = ({ customState, provider }: SignInWithRedirectInput) => {
  return async () => {
    try {
      await signInWithRedirect({ customState, provider });
    } catch (error) {
      if (isError(error)) {
        const cognitoErrorCode = error instanceof AuthError ? (error.name as CognitoErrorCode) : undefined;

        if (cognitoErrorCode === "UserAlreadyAuthenticatedException") {
          // Amplify has a bug in v6 where the current user's session isn't correctly cleared, and sign-in throws 'UserAlreadyAuthenticatedException'.
          // https://github.com/aws-amplify/amplify-js/issues/12727#issuecomment-2312871769

          // to work around this, we sign out the user and then try to sign in again.
          await signOut();

          try {
            await signInWithRedirect({ customState, provider });
          } catch {
            signOut();
          }
        }
      }
    }
  };
};
