import { PracticeMigrationSettingsRequest, PracticeVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";

export const getMigrationSettingsSchema = (onboardingType: PracticeVO["onboardingType"]) => {
  return {
    migrationPms: [
      {
        $v: required,
        $error: `Migration PMS is required`,
        $ignore: onboardingType !== "CONVERSION",
      },
    ],
  };
};

export type MigrationSettingsValidationResult = ValidationResult<
  PracticeMigrationSettingsRequest,
  ReturnType<typeof getMigrationSettingsSchema>
>;
