import { createContext, PropsWithChildren, useMemo, useContext, useCallback, useEffect } from "react";
import { noop } from "@libs/utils/noop";
import { useEnvContext } from "contexts/EnvContext";
import { AdminAccountContextValue, useAdminAccount } from "contexts/AdminAccountContext";

type AppDomain = "Migration Settings";

type TrackFn = (
  eventParams: {
    event: string;
    practiceId?: number;
    domains?: AppDomain[];
  },
  options?: { properties?: object; callback?: () => void }
) => void;
type GroupFn = (
  eventParams: {
    event: string;
    practiceId?: number;
  },
  options?: { properties?: object; callback?: () => void }
) => void;

const getSegmentAnalytics = () => {
  const appWindow: Window = window;

  return appWindow.analytics;
};

const useInitializeSegment = (user: AdminAccountContextValue) => {
  const env = useEnvContext();

  useEffect(() => {
    const analytics = getSegmentAnalytics();

    analytics?.identify(user.email, {
      isAdminPortalSuperuser: user.isAdminPortalSuperuser,
    });
  }, [user.email, user.isAdminPortalSuperuser]);

  const submitEvent = useCallback(
    (eventName: string, type: "track" | "group", properties?: object, callback?: () => void) => {
      const analytics = getSegmentAnalytics();

      if (env.REACT_APP_ENVIRONMENT === "development") {
        console.log(`Segment ${type} event "${eventName}" submitted with properties:`, properties);
      } else if (type === "track") {
        analytics?.track(eventName, properties, callback);
      } else {
        analytics?.group(eventName, properties, callback);
      }
    },
    [env]
  );

  return useMemo(() => {
    return {
      track: (
        { event, practiceId, domains }: { event: string; practiceId?: number; domains?: AppDomain[] },
        options?: {
          properties?: object;
          callback?: () => void;
        }
      ) => {
        let eventName = event;

        if (domains) {
          eventName = `${domains.map((domain) => `[${domain}]`).join(" ")} ${event}`;
        }

        submitEvent(eventName, "track", { practiceId, ...options?.properties }, options?.callback);
      },
      group: (
        { event, practiceId }: { event: string; practiceId?: number },
        options?: {
          properties?: object;
          callback?: () => void;
        }
      ) => {
        submitEvent(event, "group", { practiceId, ...options?.properties }, options?.callback);
      },
    };
  }, [submitEvent]);
};

export interface AdminSegmentValue {
  track: TrackFn;
  group: GroupFn;
}

const Context = createContext<AdminSegmentValue>({
  track: noop,
  group: noop,
});

Context.displayName = "AdminSegmentContext";
export const AdminSegment = Context;
export const AdminSegmentProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const user = useAdminAccount();
  const logger = useInitializeSegment(user);

  return <Context.Provider value={logger}>{children}</Context.Provider>;
};

export const useSegmentLogger = () => useContext(AdminSegment);
