import { ReactNode } from "react";
import { ValueTypes } from "@libs/components/UI/OptionInput";

/**
 * Maps an array of items to an array of options for either a select, checkbox list, or radio list control.
 * It ensures that saved values are included in the options and that no duplicate values are included.
 *
 * @template T The type of the items in the input array.
 * @template O The type of the options, must have a label of type ReactNode and a value of type ValueTypes.
 * @param {T[]} items - The array of items to be mapped to options.
 * @param {T[] | T | undefined} savedValues - The saved values to possibly be included in the options.
 * @param {(item: T) => O} map - The mapping function to convert items to options.
 * @returns {O[]} Returns an array of unique options for a select input.
 */
export const mapSelectionsToOptions = <T, O extends { label: ReactNode; value: ValueTypes }>(
  items: T[],
  savedValues: T[] | T | undefined,
  map: (item: T) => O
): O[] => {
  // normalize savedValues to an array
  const savedOptions = (savedValues ? (Array.isArray(savedValues) ? savedValues : [savedValues]) : []).map(
    map
  );

  // when duplicate values can be selected this makes sure we only ever add one option for a given value
  const uniqueSavedOptions: O[] = [];
  const seen = new Set<ValueTypes>([]);

  for (const savedOption of savedOptions) {
    if (!seen.has(savedOption.value)) {
      seen.add(savedOption.value);
      uniqueSavedOptions.push(savedOption);
    }
  }

  // this makes sure no values that are already in the options list get added again
  const options = items.map(map);
  const notIncluded = uniqueSavedOptions.filter(
    (savedValue) => !options.some((option) => option.value === savedValue.value)
  );

  return [...notIncluded, ...options];
};
