import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Form } from "@libs/components/UI/Form";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { RadioList } from "@libs/components/UI/RadioList";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useCallback, useRef } from "react";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useNavigate } from "react-router-dom";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { PracticeMigrationSettingsRequest, PracticeVO } from "@libs/api/generated-api";
import { flushSync } from "react-dom";
import { scrollToFirstError } from "@libs/utils/scrollToFirstError";
import { AdminPanel } from "components/UI/AdminPanel";
import { paths } from "utils/routing/paths";
import { createPractice, upsertPracticeMigrationSettings } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { MigrationSettingsFormFields } from "components/Practice/MigrationSettings/MigrationSettingsFormFields";
import { getMigrationSettingsSchema } from "components/Practice/MigrationSettings/getMigrationSettingsSchema";

const schema = {
  doingBusinessAs: [
    {
      $v: required,
      $error: `Doing Business As is required`,
    },
  ],
  onboardingType: [
    {
      $v: required,
      $error: "Practice type required",
    },
  ],
};

export const CreatePracticeDetailsRoute: React.FC = () => {
  const [draftDetails, updateDraftDetails] = useObjectState<
    Pick<PracticeVO, "doingBusinessAs" | "onboardingType">
  >({
    doingBusinessAs: "",
    onboardingType: "STARTUP",
  });
  const formRef = useRef<HTMLFormElement>(null);

  const [migrationSettingsDraft, updateDraft] = useObjectState<PracticeMigrationSettingsRequest>({});
  const navigate = useNavigate();
  const [createPracticeMutation, upsertMigrationSettingsMutation] = useApiMutations([
    createPractice,
    upsertPracticeMigrationSettings,
  ]);
  const createPracticeMutate = createPracticeMutation.mutate;
  const upsertMigrationSettings = upsertMigrationSettingsMutation.mutate;
  const { result: validationResult, validate } = useValidation(draftDetails, schema);
  const { result: migrationSettingsValidationResult, validate: migrationSettingsValidate } = useValidation(
    migrationSettingsDraft,
    getMigrationSettingsSchema(draftDetails.onboardingType)
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      const onboardingType = draftDetails.onboardingType;
      const [practiceValidation, migrationSettingsValidation] = flushSync(() => [
        validate(),
        migrationSettingsValidate(),
      ]);

      if (!practiceValidation.$isValid || !onboardingType || !migrationSettingsValidation.$isValid) {
        scrollToFirstError(formRef.current);

        return;
      }

      createPracticeMutate(
        {
          data: {
            ...draftDetails,
            onboardingStatus: "PROVISIONING",
            uuid: crypto.randomUUID(),
          },
        },
        {
          onSuccess: (data) => {
            const { id: practiceId } = data.data.data;

            if (practiceId && onboardingType === "CONVERSION") {
              return upsertMigrationSettings(
                { practiceId, data: migrationSettingsDraft },
                {
                  onSuccess: () => {
                    navigate(paths.setupPracticeAdmins({ practiceId }), { replace: true });
                  },
                }
              );
            }

            return navigate(paths.setupPracticeAdmins({ practiceId }), { replace: true });
          },
          onError: handleError,
        }
      );
    },
    [
      createPracticeMutate,
      draftDetails,
      migrationSettingsDraft,
      migrationSettingsValidate,
      navigate,
      upsertMigrationSettings,
      validate,
    ]
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} className="flex flex-col h-full overflow-y-auto">
      <AdminPanel>
        <TaskProgressHeader totalSteps={3} step={0} />

        <div className="flex flex-col gap-2">
          <h2 className="text-sm font-sansSemiBold">Practice Details</h2>
          <p className="text-xs">Add some basic information about the practice</p>
        </div>
        <div>
          <FormFieldInput
            label="Doing Business As"
            name="name"
            placeholder="Practice Name"
            required
            error={validationResult.doingBusinessAs.$error}
            value={draftDetails.doingBusinessAs}
            onChange={(e) => updateDraftDetails({ doingBusinessAs: e.target.value })}
          />
        </div>
        <div>
          <RadioList
            label="Type"
            name="practiceType"
            options={[
              { label: "Startup", value: "STARTUP" },
              { label: "Conversion", value: "CONVERSION" },
            ]}
            error={validationResult.onboardingType.$error}
            required
            onChange={(_, option) => updateDraftDetails({ onboardingType: option.value })}
            selectedValue={draftDetails.onboardingType}
          />
        </div>

        {draftDetails.onboardingType === "CONVERSION" && (
          <MigrationSettingsFormFields
            className="pb-8"
            settings={migrationSettingsDraft}
            handleUpdateSettings={updateDraft}
            edit
            validationResult={migrationSettingsValidationResult}
          />
        )}
      </AdminPanel>
      <div
        className={`
          flex
          gap-6
          justify-center
          flex-none
          p-4
          border-t
          border-t-slate-200
        `}
      >
        <ButtonInternalLink to={paths.home()} theme="secondary" className="min-w-button">
          Cancel
        </ButtonInternalLink>
        <AsyncButton
          isLoading={createPracticeMutation.isLoading || upsertMigrationSettingsMutation.isLoading}
          type="submit"
          theme="primary"
          className="min-w-button"
        >
          Next
        </AsyncButton>
      </div>
    </Form>
  );
};
