import { PracticeMigrationSettingsRequest, PracticeMigrationSettingsVO } from "@libs/api/generated-api";
import { FormFieldNumberInput } from "@libs/components/UI/FormFieldNumberInput";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { Options } from "react-select";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldTextarea } from "@libs/components/UI/FormFieldTextarea";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";
import { MigrationSettingsValidationResult } from "components/Practice/MigrationSettings/getMigrationSettingsSchema";

type FeeScheduleMigrationOption = NonNullable<PracticeMigrationSettingsVO["migrateFeeSchedules"]>;

const MIGRATE_FEE_SCHEDULES_OPTIONS: Options<SelectOption<FeeScheduleMigrationOption>> = [
  {
    value: "YES_ALL",
    label: "All",
  },
  {
    value: "YES_ONLY_UCR",
    label: "Only UCR",
  },
  {
    value: "NO",
    label: "No",
  },
];

const YES_NO_OPTIONS: Options<SelectOption<"YES" | "NO">> = [
  {
    value: "YES",
    label: "Yes",
  },
  {
    value: "NO",
    label: "No",
  },
];

type DentalPMS = NonNullable<PracticeMigrationSettingsRequest["migrationPms"]>;

const PMS_LABELS: Record<DentalPMS, string> = {
  ASCEND: "Ascend",
  CARESTACK: "CareStack",
  CURVE: "Curve",
  DENTRIX: "Dentrix",
  EAGLESOFT: "Eaglesoft",
  OTHER: "Other",
  OPENDENTAL: "Open Dental",
  PRACTICEWORKS: "PracticeWorks",
  SOFTDENT: "SoftDent",
  XLDENT: "XLDent",
};
const getLabelForPMS = (value: DentalPMS) => {
  return PMS_LABELS[value];
};
const PMS_OPTION_VALUES: DentalPMS[] = [
  "ASCEND",
  "CARESTACK",
  "CURVE",
  "DENTRIX",
  "EAGLESOFT",
  "OPENDENTAL",
  "PRACTICEWORKS",
  "SOFTDENT",
  "XLDENT",
  "OTHER",
];

const PMS_OPTIONS = mapSelectionsToOptions(PMS_OPTION_VALUES, undefined, (value) => ({
  label: getLabelForPMS(value),
  value,
}));

const SectionTitle: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="font-sansSemiBold text-sm">{children}</div>;
};

export const MigrationSettingsFormFields: React.FC<{
  settings: PracticeMigrationSettingsRequest;
  edit: boolean;
  handleUpdateSettings: (partial: Partial<PracticeMigrationSettingsRequest>) => void;
  className?: string;
  validationResult?: MigrationSettingsValidationResult;
}> = ({ settings, validationResult, handleUpdateSettings, edit, className }) => {
  const sharedProps = { edit };
  const selectProps = {
    ...sharedProps,
    placeholder: "Select an option...",
    display: "label" as const,
    isClearable: true,
    isSearchable: false,
  };
  const {
    migrateBalances,
    migrateCustomCodes,
    migrateInsurances,
    migrateFeeSchedules,
    migrationPms,
    migrationImagingSolution,
    migrationNotes,
    migrationInactivePatientDataInYears,
  } = settings;

  return (
    <div className={cx("flex flex-col gap-6", className)}>
      <SectionTitle>Migration Preferences</SectionTitle>
      <div className="grid grid-cols-2 gap-4">
        <FormFieldSelect
          {...selectProps}
          label="Migrate Fee Schedules"
          options={MIGRATE_FEE_SCHEDULES_OPTIONS}
          placeholder="Select Fee Schedules"
          value={migrateFeeSchedules}
          onChange={(option) => {
            handleUpdateSettings({ migrateFeeSchedules: option?.value });
          }}
        />
        <FormFieldNumberInput
          {...sharedProps}
          label="Number of years of patient data to migrate"
          placeholder="Enter number of years"
          onValueChange={(newValue) =>
            handleUpdateSettings({ migrationInactivePatientDataInYears: newValue })
          }
          value={migrationInactivePatientDataInYears}
          step={1}
        />
        <FormFieldSelect
          {...selectProps}
          label="Migrate Balances"
          value={migrateBalances}
          options={YES_NO_OPTIONS}
          onChange={(option) => {
            handleUpdateSettings({ migrateBalances: option?.value });
          }}
        />
        <FormFieldSelect
          {...selectProps}
          label="Migrate Custom Codes"
          value={migrateCustomCodes}
          options={YES_NO_OPTIONS}
          onChange={(option) => {
            handleUpdateSettings({ migrateCustomCodes: option?.value });
          }}
        />
        <FormFieldSelect
          {...selectProps}
          label="Migrate Insurance"
          value={migrateInsurances}
          options={YES_NO_OPTIONS}
          onChange={(option) => {
            handleUpdateSettings({ migrateInsurances: option?.value });
          }}
        />
      </div>
      <SectionTitle>Migration Configuration</SectionTitle>
      <div className="grid grid-cols-2 gap-6">
        <FormFieldSelect
          {...selectProps}
          label="Migration PMS"
          value={migrationPms}
          options={PMS_OPTIONS}
          error={validationResult?.migrationPms.$error}
          onChange={(option) => {
            handleUpdateSettings({ migrationPms: option?.value });
          }}
        />
        <FormFieldInput
          {...sharedProps}
          label="Migration Imaging Solution"
          placeholder="Enter imaging solution"
          value={migrationImagingSolution}
          onChange={(e) => {
            handleUpdateSettings({ migrationImagingSolution: e.target.value });
          }}
        />
        <FormFieldTextarea
          {...sharedProps}
          label="Notes"
          className="col-span-full"
          placeholder="Enter notes about this migration"
          value={migrationNotes}
          rows={20}
          onChange={(e) => {
            handleUpdateSettings({ migrationNotes: e.target.value });
          }}
        />
      </div>
    </div>
  );
};
